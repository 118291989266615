import { useScreenWidth } from "@/hooks/useScreenWidth";
import type { IProductPreview } from "@/types/types";
import { Flex, MaterialIcon, Text } from "@talent-garden/react-components";
import clsx from "clsx";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./purchasePreviewBanner.module.scss";

const PurchasePreviewBanner: FC<{
  className?: string;
  purchaseItem: IProductPreview;
}> = ({ className, purchaseItem }) => {
  const { t } = useTranslation("sign-in");
  const { isMobile } = useScreenWidth();
  if (!purchaseItem) return null;
  return (
    <Flex
      direction="row"
      className={clsx(styles.container, className)}
      verticalPadding={12}
      horizontalPadding={16}
      verticalAlign="center"
      columnGap={16}
    >
      <Flex
        className={styles.iconWrapper}
        horizontalAlign="center"
        verticalAlign="center"
        style={{
          backgroundImage: `url("${purchaseItem?.img || ""}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      >
        {!purchaseItem?.img ? (
          <MaterialIcon name="local_mall" size={32} mode="gray" />
        ) : null}
      </Flex>
      <Flex
        direction="column"
        className={styles.infoWrapper}
        verticalAlign="space-between"
        rowGap={4}
        columnGap={16}
      >
        <Flex rowGap={4}>
          <Text
            className={styles.grayText}
            variant={isMobile ? "s" : "m"}
            weight="regular"
          >
            {t("purchase-preview-banner-title")}
          </Text>
          <Text variant="m" weight="semibold">
            {purchaseItem?.name}
          </Text>
        </Flex>
        <Text
          className={styles.grayText}
          variant={isMobile ? "s" : "m"}
          weight="regular"
        >
          {purchaseItem?.currency?.toLowerCase() === "eur"
            ? "€"
            : purchaseItem?.currency}
          {purchaseItem?.price}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PurchasePreviewBanner;
